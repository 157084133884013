import { useAppSelector } from 'hooks/hooks';
import { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { createRoleAccessChecker } from 'utils/userRolesUtils/userRolesUtils';
import { StyledPageWrapper } from 'components/fragments/PageWrapper/styled';
import { SubmitHandler, useForm } from 'react-hook-form';
import { usePostMowerGatewayDebugRequestMutation } from 'store/api/tools-v1';
import { roles } from '../../constants';
import ToolSideBar from '../../components/fragments/ToolSidebar/ToolSidebar';
import { StyleMowerGatewayDebuggingPresentation } from './styled';
import { FormInput, MowerGatewayDebuggingForm } from './MowerGatewayDebuggingForm';

export const MowerGatewayDebuggingPage = () => {
  const { role } = useAppSelector((state) => state.auth.session);
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentEnvironment = searchParams.get('env') ?? 'qa';
  const paramDeviceId = searchParams.get('mowerId') ?? undefined;
  const [deviceId, setDeviceId] = useState<string | undefined>(paramDeviceId);
  if (role === roles.Unauthorized || !createRoleAccessChecker(role)(location.pathname)) {
    navigate('/', { replace: true });
  }

  const [
    sendMowerGatewayDebuggingRequest,
    { isLoading: mutationIsLoading, isSuccess: mutationSuccess, error: mutationError },
  ] = usePostMowerGatewayDebugRequestMutation();

  const onSubmit: SubmitHandler<FormInput> = (formData) => {
    sendMowerGatewayDebuggingRequest(formData);
    const oldParams = Object.fromEntries(searchParams);
    const params = { ...oldParams, mowerId: formData.deviceId };
    setSearchParams(params, { replace: true });
    setDeviceId(formData.deviceId);
  };

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<FormInput>({
    defaultValues: {
      deviceId: deviceId ?? '',
    },
  });

  return (
    <>
      <ToolSideBar>
        <MowerGatewayDebuggingForm
          onSubmit={handleSubmit(onSubmit)}
          register={register}
          errors={formErrors}
          placeholder="123456789-123456789"
        />
      </ToolSideBar>
      <StyledPageWrapper>
        <StyleMowerGatewayDebuggingPresentation>
          <h1>Mower Gateway Debugging</h1>
          <hr />
          {mutationIsLoading && <h4>Sending request...</h4>}
          {mutationError && (
            <h2>Failed to activate gateway debug logging for device {deviceId}.</h2>
          )}
          {mutationSuccess && <h3>Activated gateway debug logging for device {deviceId}!</h3>}
          <h4>Instructions:</h4>
          <p>
            Enter the Device Id of the mower for which you want extended debug logging in the AMC
            Gateways. The device ID for a G3 mower is the nine-digit serial number of the mower,
          </p>
          <p>
            followed by a dash, followed by the nine-digit comboard ID. For G4 mowers, just input
            the nine-digit serial number of the mower.
          </p>
          <p>
            To inspect the logs yourself, make sure that you have VPN access via GlobalProtect and
            then visit the corresponding Kibana environment (Dev and QA share Kibana environment).
          </p>
          <p>
            If you require assistance to inspect the logs, contact AMC Backend through the Slack
            channel <b>#amc-support</b> (or via Teams if you do not have Slack).
          </p>
          <p>
            Please note that the debug logging will be reset when a new deployment of the gateways
            in <b>{currentEnvironment === 'qa' ? 'QA' : currentEnvironment}</b> is made.
          </p>
        </StyleMowerGatewayDebuggingPresentation>
      </StyledPageWrapper>
    </>
  );
};
